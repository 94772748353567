
import Vue from "@/interfaces/vue";
import { Component } from "vue-property-decorator";
import Newsletter from "@/components/common/Newsletter.vue";
import Content from "@/components/common/Content.vue";
import i18n from "@/i18n";
const pageKey = "about";

@Component({
  components: { Newsletter, Content },
  metaInfo: {
    title: i18n.t(`page-title.${pageKey}`).toString(),
    meta: [
      {
        name: "description",
        content: i18n.t(`meta-description.${pageKey}`).toString(),
      },
    ],
  },
})
export default class About extends Vue {}
